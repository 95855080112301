import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/homepage",
    component: () => import("@/layouts/app-layout/Layout.vue"),
    children: [
      {
        path: "/homepage",
        name: "HomePage",
        component: () => import("@/views/HomePage.vue"),
      },
      {
        path: "/hospitals-clinics",
        name: "HospitalsClinics",
        component: () => import("@/views/HospitalsClinics.vue"),
      },
      {
        path: "/hospitals",
        name: "Hospitals",
        component: () => import("@/views/Hospitals.vue"),
      },
      {
        path: "/hospital-profile/:id",
        name: "HospitalProfile",
        component: () => import("@/views/HospitalProfile.vue"),
      },
      {
        path: "/clinics",
        name: "Clinics",
        component: () => import("@/views/Clinics.vue"),
      },
      {
        path: "/clinic-profile/:id",
        name: "ClinicProfile",
        component: () => import("@/views/ClinicProfile.vue"),
      },
      {
        path: "/medical-services",
        name: "MedicalServices",
        component: () => import("@/views/MedicalServices.vue"),
      },
      {
        path: "/doctors",
        name: "Doctors",
        component: () => import("@/views/Doctors.vue"),
      },
      {
        path: "/certificate-doctors",
        name: "CertificateDoctors",
        component: () => import("@/views/CertificateDoctor.vue"),
      },
      {
        path: "/doctor-profile/:id",
        name: "DoctorProfile",
        component: () => import("@/views/DoctorProfile.vue"),
      },
      {
        path: "/specialties-services",
        name: "SpecialtiesServices",
        component: () => import("@/views/SpecialtiesServices.vue"),
      },
      {
        path: "/specialty-profile/:id",
        name: "SpecialtyProfile",
        component: () => import("@/views/SpecialtyProfile.vue"),
      },
      {
        path: "/other-services",
        name: "OtherServices",
        component: () => import("@/views/OtherServices.vue"),
      },
      {
        path: "/restaurants-leisures",
        name: "RestaurantsLeisures",
        component: () => import("@/views/RestaurantsLeisures.vue"),
      },
      {
        path: "/restaurants",
        name: "Restaurants",
        component: () => import("@/views/Restaurants.vue"),
      },
      {
        path: "/restaurant-profile/:id",
        name: "RestaurantProfile",
        component: () => import("@/views/RestaurantProfile.vue"),
      },
      {
        path: "/leisures",
        name: "Leisures",
        component: () => import("@/views/Leisures.vue"),
      },
      {
        path: "/leisure-profile/:id",
        name: "LeisureProfile",
        component: () => import("@/views/LeisureProfile.vue"),
      },
      {
        path: "/hotels",
        name: "Hotels",
        component: () => import("@/views/Hotels.vue"),
      },
      {
        path: "/hotel-profile/:id",
        name: "HotelProfile",
        component: () => import("@/views/HotelProfile.vue"),
      },
      {
        path: "/houses",
        name: "Houses",
        component: () => import("@/views/Houses.vue"),
      },
      {
        path: "/house-profile/:id",
        name: "HouseProfile",
        component: () => import("@/views/HouseProfile.vue"),
      },
      {
        path: "/pharmaceutics",
        name: "Pharmaceutics",
        component: () => import("@/views/Pharmaceutics.vue"),
      },
      {
        path: "/pharmaceutic-profile/:id",
        name: "PharmaceuticProfile",
        component: () => import("@/views/PharmaceuticProfile.vue"),
      },
      {
        path: "/laboratories",
        name: "Laboratories",
        component: () => import("@/views/Laboratories.vue"),
      },
      {
        path: "/laboratory-profile/:id",
        name: "LaboratoryProfile",
        component: () => import("@/views/LaboratoryProfile.vue"),
      },
      {
        path: "/transportations",
        name: "Transportations",
        component: () => import("@/views/Transportations.vue"),
      },
      {
        path: "/transportation-profile/:id",
        name: "TransportationProfile",
        component: () => import("@/views/TransportationProfile.vue"),
      },
      {
        path: "/vineyards",
        name: "Vineyards",
        component: () => import("@/views/Vineyards.vue"),
      },
      {
        path: "/vineyard-profile/:id",
        name: "VineyardProfile",
        component: () => import("@/views/VineyardProfile.vue"),
      },
      {
        path: "/comments",
        name: "Comments",
        component: () => import("@/views/Comments.vue"),
      },
      {
        path: "/jointobhc",
        name: "JoinToBHC",
        component: () => import("@/views/JoinToBHC.vue"),
      },
      {
        path: "/contacts",
        name: "Contacts",
        component: () => import("@/views/Contacts.vue"),
      },
      {
        path: "/blogs",
        name: "Blogs",
        component: () => import("@/views/Blogs.vue"),
      },
      {
        path: "/blog-post/:id",
        name: "BlogPost",
        component: () => import("@/views/BlogPost.vue"),
      },
      {
        path: "/alliances",
        name: "Alliances",
        component: () => import("@/views/Alliances.vue"),
      },
      {
        path: "/alliance-profile/:id",
        name: "AllianceProfile",
        component: () => import("@/views/AllianceProfile.vue"),
      },
      {
        path: "/about",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/About.vue"),
      },
    ],
  },
  {
    path: "/authentication",
    redirect: "/formlogin",
    component: () => import("@/layouts/login-layout/LoginLayout.vue"),
    children: [
      {
        path: "/formlogin",
        name: "FormLogin",
        component: () => import("@/views/authentication/FormLogin.vue"),
      },
      {
        path: "/error",
        name: "Error",
        component: () => import("@/views/authentication/Error.vue"),
      },
    ],
  },
  {
    path: "/admin-page",
    redirect: "/dashboard",
    component: () => import("@/layouts/admin-layout/AdminLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard/Statistics.vue"),
      },
      {
        path: "/add-clinic",
        name: "AddClinic",
        component: () => import("@/views/admin/gest-clinic/AddClinicView.vue"),
      },
      {
        path: "/update-clinic/:id",
        name: "UpdateClinic",
        component: () => import("@/views/admin/gest-clinic/UpdateClinicView.vue"),
      },
      {
        path: "/admin-user",
        name: "AdminUser",
        component: () => import("@/views/admin/AdminUser.vue"),
      },
      {
        path: "/admin-cart",
        name: "AdminCart",
        component: () => import("@/views/admin/AdminCart.vue"),
      },
      {
        path: "/company",
        name: "Company",
        component: () => import("@/views/admin/Company.vue"),
      },
      {
        path: "/nom-cities",
        name: "NomCities",
        component: () => import("@/views/admin/Cities.vue"),
      },
      {
        path: "/nom-associations",
        name: "NomAssociations",
        component: () => import("@/views/admin/Associations.vue"),
      },
      {
        path: "/nom-certifications",
        name: "NomCertifications",
        component: () => import("@/views/admin/Certifications.vue"),
      },
      {
        path: "/nom-specialties",
        name: "NomSpecialties",
        component: () => import("@/views/admin/Specialties.vue"),
      },
      {
        path: "/nom-subspecialties",
        name: "NomSubspecialties",
        component: () => import("@/views/admin/Subspecialties.vue"),
      },
      {
        path: "/nom-extraservices",
        name: "NomExtraServices",
        component: () => import("@/views/admin/ExtraServices.vue"),
      },
      {
        path: "/nom-blogscategories",
        name: "NomBlogsCategories",
        component: () => import("@/views/admin/BlogsCategories.vue"),
      },
      {
        path: "/mod-hospitals",
        name: "HospitalsModule",
        component: () => import("@/views/admin/HospitalsModule.vue"),
      },
      {
        path: "/mod-clinics",
        name: "ClinicsModule",
        component: () => import("@/views/admin/ClinicsModule.vue"),
      },
      {
        path: "/mod-doctors",
        name: "DoctorsModule",
        component: () => import("@/views/admin/DoctorsModule.vue"),
      },
      {
        path: "/mod-pharmaceutic",
        name: "PharmaceuticsModule",
        component: () => import("@/views/admin/PharmaceuticsModule.vue"),
      },
      {
        path: "/mod-laboratories",
        name: "LaboratoriesModule",
        component: () => import("@/views/admin/LaboratoriesModule.vue"),
      },
      {
        path: "/mod-restaurants",
        name: "RestaurantsModule",
        component: () => import("@/views/admin/RestaurantsModule.vue"),
      },
      {
        path: "/mod-leisures",
        name: "LeisuresModule",
        component: () => import("@/views/admin/LeisuresModule.vue"),
      },
      {
        path: "/mod-hotels",
        name: "HotelsModule",
        component: () => import("@/views/admin/HotelsModule.vue"),
      },
      {
        path: "/mod-houses",
        name: "HousesModule",
        component: () => import("@/views/admin/HousesModule.vue"),
      },
      {
        path: "/mod-transports",
        name: "TransportsModule",
        component: () => import("@/views/admin/TransportsModule.vue"),
      },
      {
        path: "/mod-vineyards",
        name: "VineyardsModule",
        component: () => import("@/views/admin/VineyardsModule.vue"),
      },
      {
        path: "/mod-blogs",
        name: "BlogsModule",
        component: () => import("@/views/admin/BlogsModule.vue"),
      },
      {
        path: "/mod-alliances",
        name: "AlliancesModule",
        component: () => import("@/views/admin/AlliancesModule.vue"),
      },
      {
        path: "/mod-comments",
        name: "CommentsModule",
        component: () => import("@/views/admin/CommentsModule.vue"),
      },
      {
        path: "/mod-contacts",
        name: "ContactsModules",
        component: () => import("@/views/admin/ContactsModules.vue"),
      },
      {
        path: "/mod-jointobhc",
        name: "JoinToBHCModule",
        component: () => import("@/views/admin/JoinToBHCModule.vue"),
      },
      {
        path: "/mod-carrusel",
        name: "CarruselModule",
        component: () => import("@/views/admin/CarruselModule.vue"),
      },
      {
        path: "/carousel",
        name: "CarouselView",
        component: () => import("@/views/CarouselView.vue"),
      },
    ],
  },
  {
    path: "/under-construction",
    name: "UnderConstruction",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/UnderConstruction.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes,
});

const auths = ['Dashboard', 'AdminUser', 'AdminCart', 'Company', 'NomCities', 'NomAssociations', 'NomCertifications', 
               'NomSpecialties', 'NomSubspecialties', 'NomExtraServices', 'NomBlogsCategories', 'HospitalsModule', 
               'ClinicsModule', 'DoctorsModule', 'PharmaceuticsModule', 'LaboratoriesModule', 'RestaurantsModule', 
               'LeisuresModule', 'HotelsModule', 'HousesModule', 'TransportsModule', 'VineyardsModule', 'BlogsModule', 
               'AlliancesModule', 'CommentsModule', 'ContactsModules', 'JoinToBHCModule', 'CarruselModule', 
               'CarouselView'];

router.beforeEach(async (to, from, next) => {
    console.log(from);
    const token = sessionStorage.getItem('token');

    if (!token && auths.find(e=>e==to.name)) {
        return next({ name: 'FormLogin' })
    }
    else if(token && to.name == 'FormLogin'){
        return next({ name: 'Dashboard' });
    }
    next();
});

export default router;
